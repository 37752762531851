<template>
  <div id="donation">
    <DonationHeader>
      <LoadingPanel
        :promise="submissionPromise"
        @success="handleSuccess"
      >
        <template #loading>
          <font-awesome-icon
            icon="spinner"
            pulse
          />
          <h4>{{ $t('paymentStatus.processing') }}</h4>
        </template>
        <template #failure="failure">
          <h4>{{ $t('paymentStatus.failure') }}</h4>
          <span class="error">{{ failure.result.failure_message }}</span>
          <button @click="resetPayment">
            {{ $t('paymentStatus.changePaymentMethod') }}
          </button>
        </template>
      </LoadingPanel>
      <div class="columns">
        <div class="column">
          <ProgressTracker :current-step="3" />
          <DonationSummary />
          <PaymentMethodSelect @set-stripe-card="setStripeCard" />
          <button
            class="submit"
            @click="submit"
            :class="{ disabled: !donationValid }"
          >
            {{ $t('donationForm.confirm') }}
          </button>
          <p class="donation-nav">
            <router-link
              :to="{ name: $t('routeNames.personalDetails') }"
              class="btn btn-primary back"
            >
              <font-awesome-icon icon="arrow-left" />
              {{ $t('donationForm.back') }}
            </router-link>
          </p>
          <div id="turnstile-container" />
        </div>
      </div>
    </DonationHeader>
    <DonationFooter />
  </div>
</template>

<script>
import DonationHeader from './donation/layout/DonationHeader'
import DonationFooter from './donation/layout/DonationFooter'
import submitDonation from '@/lib/donation/submitDonation'
import PaymentMethodSelect from '@/components/donation/PaymentMethodSelect'
import ProgressTracker from '@/components/donation/ProgressTracker'
import DonationSummary from '@/components/donation/DonationSummary'
import LoadingPanel from '@/components/LoadingPanel'
import { v4 as uuid } from 'uuid'
import { useDonationStore } from '@/stores/donation'
import { usePaymentStore } from '@/stores/payment'
import { useRootStore } from '@/stores/root'
import { ref } from 'vue'

export default {
  name: 'PaymentDetails',
  components: {
    DonationFooter,
    DonationHeader,
    PaymentMethodSelect,
    LoadingPanel,
    ProgressTracker,
    DonationSummary
  },
  setup () {
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    const paymentStore = usePaymentStore()
    const submissionPromise = ref(null)
    const stripeCard = ref(null)
    const turnstileToken = ref(null)
    return {
      donationStore, paymentStore, rootStore, submissionPromise,
      stripeCard, turnstileToken
    }
  },
  computed: {
    thankYouPath () { return this.$t('routeNames.thankYou') },
    donationValid () { return this.rootStore.formValid && this.turnstileToken },
  },
  methods: {
    submit () { submitDonation(this, localStorage) },
    resetPayment () {
      this.donationStore.setReference(uuid())
      this.paymentStore.reset()
      this.submissionPromise = null
    },
    handleSuccess () { this.$router.push({ name: this.thankYouPath }) },
    setStripeCard (card) { this.stripeCard = card } // The submit > serialize method requires this, and it cannot be stored within vuex (dynamic)
  },
  mounted () {
    this.$scrollToElement('.column')
    this.$loadScript('https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit', { async: false }).then(() => {
      turnstile.ready(() => {
        turnstile.render("#turnstile-container", {
          sitekey: process.env.VUE_APP_TURNSTILE_SITE_KEY,
          theme: 'light',
          size: 'normal',
          language: this.$l10n.currentLanguage,
          callback: (token) => { this.turnstileToken = token }
        });
      });
    })
  }
}
</script>

<style lang="scss" scoped>
  .columns {
    @include on-desktop() {
      display: flex;
      margin: 0 -0.5rem;
    }

    .column {
      margin-bottom: 1.5rem;

       @include on-desktop(){
        flex: 1;
        margin: 0;
        padding: 0.5rem 1rem;
      }
    }
  }

  button.submit {
    border: none;
    padding: 0.6em 1em;
    text-align: center;
    width: 100%;
    margin-top: 1em;
    background-color: orange;
    color: black;
    text-shadow: 0 1px 0 white;
    box-shadow: 2px 2px 7px rgba(black, 0.3);

    &.disabled {
      opacity: 0.7;
    }
  }
</style>
