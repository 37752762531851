<template>
  <div id="app">
    <template v-if="loading" />
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
import domainToLocationMap from './locations/domainToLocationMap'
import locationParamMap from './locations/locationParamMap'
import { computed, reactive, ref } from 'vue'
import { useHead } from '@vueuse/head'
import { useRootStore } from '@/stores/root'
import { useDonationStore } from '@/stores/donation'

export default {
  name: 'ViruRoot',
  setup () {
    const loading = ref(true)
    const siteData = reactive({ title: 'Donate - Survival International', description: '' })
    useHead({
      title: computed(() => siteData.title),
      meta: [{ name: 'description', content: computed(() => siteData.description) }]
    })
    const rootStore = useRootStore()
    const donationStore = useDonationStore()
    return { siteData, rootStore, donationStore, loading }
  },
  beforeMount () {
    const currentDomain = window.location.hostname.replace(/local.|staging-/gi, '')
    this.rootStore.setCurrentDomain(currentDomain)

    const isEnDomain = (currentDomain === 'donate.survivalinternational.org')
    this.rootStore.setIsEnDomain(isEnDomain)

    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search))
    this.rootStore.setQueryParams(queryParams)
    this.donationStore.setUtms(queryParams)

    const omittedFrequency = ('omitFrequency' in queryParams) ? (['oneOff', 'regular'].includes(queryParams.omitFrequency) ? queryParams.omitFrequency : false) : false
    if (omittedFrequency) {
      if (omittedFrequency === 'oneOff') { this.donationStore.setType('monthly') }
      if (omittedFrequency === 'regular') { this.donationStore.setType( 'oneOff') }
    }
    this.rootStore.setOmittedFrequency(omittedFrequency)

    const appeal = queryParams.appeal
    if (appeal) { this.rootStore.setAppeal(appeal) }

    const currentLocationKey = this.locationFromParam(queryParams) || this.inferLocation(currentDomain)
    this.$l10n.changeLocation(currentLocationKey).then(() => {
      this.siteData.title = `${this.$t('meta.title')} - Survival International`
        this.loading = false
    })
  },
  methods: {
    inferLocation (domain) {
      return domainToLocationMap[domain] || 'us'
    },
    locationFromParam (queryParams) {
      if ('l' in queryParams) {
        return locationParamMap[queryParams.l]
      } else { return false }
    }
  }
}
</script>

<style lang="scss">
  @import 'assets/sass/layout';

  #app {
    position: relative;
  }
</style>
