export default {
  install (app) {
    app.config.globalProperties.$loadScript = (source, config = { async: true }) => {
      const existing = document.querySelector(`script[src="${source}"]`)

      if (existing) {
        return existing.promise
      }

      const script = document.createElement('script')

      return (script.promise = new Promise((resolve, reject) => {
        script.type = 'text/javascript'
        script.async = config.async

        script.addEventListener('load', resolve)
        script.addEventListener('error', reject)
        script.addEventListener('abort', reject)

        script.src = source

        document.head.appendChild(script)
      }))
    }
  }
}
